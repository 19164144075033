/* eslint-disable jsx-a11y/anchor-is-valid */
// import { PhoneOutlined, MailOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import URL from "constant/url";
// import DedicatedServerCategory from "./DedicatedServerCategory";
// import ProxyCategory from "./ProxyCategory";
// import ServerCategory from "./ServerCategory";
// import { useState } from "react";

import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";

export default function Header() {
  const navigate = useNavigate();
  // const [isLogin, setLogin] = useState(false);
  const domain = window.location.hostname;
  const logo_w = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-w.png`;

  return (
    <>
      <>
        {/* Menu Area Start */}
        <div id="menu">
          {/* Menu Topbar Start */}
          <div className="menu--topbar">
            <div className="container">
              {/* Menu Topbar Contact Start */}
              <div className="menu-topbar--contact">
                <ul className="nav navbar-nav">
                  <li>
                    <a href="tel:+4440000000">
                      <i className="fa fa-phone" />
                      +(84)345993883
                    </a>
                  </li>
                  <li>
                    <a href="mailto:admin@vpschinhhang247.com">
                      <i className="fa fa-envelope" />
                      admin@vpschinhhang247.com
                    </a>
                  </li>
                </ul>
              </div>
              {/* Menu Topbar Contact End */}
              {/* Menu Topbar Social Start */}
              <ul className="menu-topbar--social nav navbar-nav navbar-right">
                <li>
                  <a href="https://www.facebook.com/VPSChinhHang247">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/vpschinhhhang247">
                    <i className="fa fa-telegram" />
                  </a>
                </li>
              </ul>
              {/* Menu Topbar Social End */}
            </div>
          </div>
          {/* Menu Topbar End */}
          {/* Primary Menu Start */}
          <nav id="primaryMenu" className="navbar primary-menu-two">
            <div className="container">
              {/* Logo Start */}
              <div className="primary--logo">
                <h1>
                  <a href="/">
                    <img
                      src={logo_w}
                      alt=""
                      className="img-responsive"
                    />
                  </a>
                </h1>
              </div>
              {/* Logo End */}
              <div className="primary--info clearfix">
                <div className="primary--info-item">
                  <div className="primary--icon">
                    <img
                      src="img/top-bar-icons/01.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="primary--content">
                    <h5>Hỗ Trợ 24/7</h5>
                    <p>Chăm sóc khách hàng chuyên nghiệp</p>
                  </div>
                </div>
                <div className="primary--info-item">
                  <div className="primary--icon">
                    <img
                      src="img/top-bar-icons/02.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="primary--content">
                    <h5>Hoàn Trả</h5>
                    <p>Hoàn tiền nếu không hài lòng</p>
                  </div>
                </div>
                <div className="primary--info-item">
                  <div className="primary--icon">
                    <img
                      src="img/top-bar-icons/03.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="primary--content">
                    <h5>Dễ Dàng Sử Dụng</h5>
                    <p>Quản lý đều tự động và dễ dàng</p>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* Primary Menu End */}
          {/* Secondary Menu Start */}
          <nav id="secondaryMenu" className="navbar">
            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#secondaryNavbar"
                  aria-expanded="false"
                  aria-controls="secondaryNavbar"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <div className="login-btn hidden-lg hidden-md hidden-sm">
                  <a href="login.html" className="btn">
                    Client Area
                  </a>
                </div>
              </div>
              {/* Secondary Menu Links Start */}
              <div
                id="secondaryNavbar"
                className="reset-padding navbar-collapse collapse"
              >
                <ul className="secondary-menu-links nav navbar-nav">
                  <li className="dropdown active">
                    <a href={URL.Home}>Trang chủ</a>
                  </li>
                  <ServerCategory />
                  <DedicatedServerCategory />
                  <ProxyCategory />
                  {/* <li>
                    <a onClick={() => navigate(URL.Guide)}>Hướng dẫn</a>
                  </li> */}
                  <li>
                    <a onClick={() => navigate(URL.Agreement)}>Điều khoản</a>
                  </li>
                  <li>
                    <a onClick={() => navigate(URL.SLA)}>SLA</a>
                  </li>
                  <li>
                    <a onClick={() => navigate(URL.Contact)}>Liên hệ</a>
                  </li>
                </ul>
                <ul className="secondary-menu-links nav navbar-nav navbar-right hidden-xs">
                  <li>
                    <a href={URL.Login} className="btn">
                      Đăng nhập
                    </a>
                  </li>
                </ul>
              </div>
              {/* Secondary Menu Links End */}
            </div>
          </nav>
          {/* Secondary Menu End */}
        </div>
        {/* Menu Area End */}
      </>
    </>
  );
}

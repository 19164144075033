/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import URL from "constant/url";

export default function SLA() {
  const navigate = useNavigate();
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient" style={{marginTop: '240px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <nav aria-label="breadcrumb">
                <div className="pt-breadcrumb-title">
                  <h1>Cam Kết Chất Lượng Dịch Vụ</h1>
                  <p className="mt-4">
                    Chúng tôi đảm bảo dịch vụ của Quý khách có chất lượng dịch
                    vụ tốt nhất.
                  </p>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container position-relative mt-60">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="hm2-section-title">
              <h2 className="hm2-title">Chất Lượng Dịch Vụ Tốt Nhất</h2>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="hm2-section-desc mt-3 mt-lg-0">
              <p>
                Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt uptime
                từ 99,99% trở lên.
              </p>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(URL.SLADetail);
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                className="hm2-explore-btn fw-bold position-relative"
              >
                Xem thêm
              </a>
            </div>
          </div>
        </div>
        <div className="row g-4 mt-5 justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="hm2-feature-card deep-shadow bg-white rounded-2">
              <span className="icon-wrapper d-flex align-items-center justify-content-center rounded-circle">
                <i className="fa-solid fa-lock" />
              </span>
              <h3 className="h4 mt-4">100% Host Node Uptime</h3>
              <p className="mt-3">
                Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn được
                thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm kỹ sư
                của chúng tôi sử dụng giám sát tích cực để chủ động phát hiện
                các vấn đề và thực hiện các biện pháp phòng ngừa, giảm thiểu bất
                kỳ tác động nào khi phần cứng máy chủ bị lỗi có thể gây ra đối
                với môi trường của bạn.
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="hm2-feature-card deep-shadow bg-white rounded-2">
              <h3 className="h4 mt-4">100% Network Uptime</h3>
              <p className="mt-3">
                Sử dụng kiến trúc dự phòng và định tuyến BGP thông minh, mạng
                lõi định tuyến một cách thông minh xung quanh hoạt động mạng
                gián đoạn nhằm nỗ lực cung cấp 100% thời gian hoạt động và kết
                nối hiệu suất cao trong tất cả các POP trên toàn thế giới của
                chúng tôi.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container ptb-60">
          <div className="row">
            <div className="text-center">
              <h5 className="pt-section-title">Cam Kết Đền Bù Phí Dịch Vụ</h5>
              <p className="pt-section-description">
                Trường hợp tỷ lệ hoạt động hàng tháng của máy chủ ảo giảm xuống
                dưới 99,9%, chúng tôi sẽ khấu trừ số tiền được tính theo tỷ lệ
                hiển thị bên dưới cho chi phí sử dụng trong tháng xảy ra sự cố.
              </p>
            </div>

            <div className="col-12">
              <div className="pt-comparison-table table-style-1 table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <div className="cell-inner">
                          <span>Thời gian uptime hàng tháng</span>
                        </div>
                        <div className="cell-tag">
                          <span></span>
                        </div>
                      </th>
                      <th>
                        <div className="cell-inner">
                          <span>Thời gian ở trạng thái không sẵn sàng</span>
                        </div>
                        <div className="cell-tag">
                          <span></span>
                        </div>
                      </th>
                      <th>
                        <div className="cell-inner">
                          <span>Mức phí dịch vụ đền bù</span>
                        </div>
                        <div className="cell-tag">
                          <span></span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="cell-inner">
                          <span>Từ 99,9% tới 100%</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>Tối đa 43 phút</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>0%</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="cell-inner">
                          <span>Từ 99% tới ít hơn 99,9%</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>Tối đa 430 phút</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>30%</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="cell-inner">
                          <span>Từ 90% tới ít hơn 99%</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>Tối đa 4300 phút</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>50%</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="cell-inner">
                          <span>Ít hơn 90%</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>Hơn 4300 phút</span>
                        </div>
                      </td>
                      <td>
                        <div className="cell-inner">
                          <span>100%</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center">
                  <div className="pt-btn-container">
                    <div className="pt-button-block">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(URL.SLADetail);
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                        className="hm2-explore-btn fw-bold position-relative"
                      >
                        Xem thêm
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <li className="dropdown">
      <a href="#" data-toggle="dropdown">
        VPS
        <span className="caret" />
      </a>
      <ul className="dropdown-menu" style={{ width: "500px", padding: "8px" }}>
        <div className="row">
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <h6>KHU VỰC VIỆT NAM</h6>
              <div
                className="d-flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(URL.ListServerVN);
                }}
              >
                <a>
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="img/flags/vietnam.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span>VPS Việt Nam (VN)</span>
                </a>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC CHÂU Á</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerSG);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/singapore.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Singapore (SG)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerAU);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/australia.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Úc (AU)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC CHÂU ÂU</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerUK);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/united-kingdom.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Anh (UK)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerDE);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/germany.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Đức (DE)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerFR);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/france.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Pháp (FR)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC ÂU MỸ</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerUS);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/united-states.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Mỹ (US)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerCA);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/canada.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Canada (CA)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>THEO MỤC ĐÍCH</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListServerEU);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/european-union.png"
                      alt="server"
                      width={35}
                      height={35}
                    ></img>
                    <span>VPS Châu Âu (PTU)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </li>
  );
}

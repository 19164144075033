/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ProxyCategory() {
  const navigate = useNavigate();
  const [isHideMenu, setIsHideMenu] = useState(false);
  return (
    <li className="dropdown">
      <a href="#" data-toggle="dropdown">
      Proxy
        <span className="caret" />
      </a>
      <ul
        className="dropdown-menu"
        style={{ width: "510px", padding: "8px" }}
        hidden={isHideMenu}
      >
        <div className="row">
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC VIỆT NAM</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyVN);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/vietnam.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Việt Nam</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC CHÂU Á</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxySG);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/singapore.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Singapore (SG)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyAU);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/australia.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Úc (AU)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC CHÂU ÂU</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyUK);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/united-kingdom.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Anh (UK)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyDE);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/germany.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Đức (DE)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyFR);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/france.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Pháp (FR)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="col-md-6">
            <div className="menu-list-wrapper d-flex align-items-center mt-2">
              <div className="menu-list-content-right ms-3">
                <h6>KHU VỰC ÂU MỸ</h6>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyUS);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/united-states.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                    <span>Proxy Mỹ (US)</span>
                  </a>
                </div>
                <div
                  className="d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.ListProxyCA);
                    setIsHideMenu(true);
                  }}
                >
                  <a>
                    <img
                      style={{
                        alignSelf: "center",
                        marginRight: "6px",
                      }}
                      src="img/flags/canada.png"
                      alt="proxy"
                      width={35}
                      height={35}
                    ></img>
                   <span> Proxy Canada (CA)</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </li>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
// import URL from "constant/url";
// import { useNavigate } from "react-router-dom";

export default function Footer() {
  // const navigate = useNavigate();
  const domain = window.location.hostname;
  const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;

  return (
    <>
      {/* Footer Area Start */}
      <div id="footer">
        <div className="container">
          <div className="row">
            {/* Footer Logo Widget Start */}
            <div className="col-md-3 col-sm-6 footer-widget">
              <img
                src={logo_r}
                alt=""
                className="img-responsive"
              />
            </div>
            {/* Footer Logo Widget End */}
            {/* Footer About Widget Start */}
            <div className="col-md-6 footer-widget">
              <div className="footer-about">
                {/* Footer Title Start */}
                <h4>CÔNG TY TNHH CÔNG NGHỆ SỐ TRỊNH VIỆT</h4>
                {/* Footer Title End */}
                <p>SHA5 Toà nhà Anland Lakeview, KĐT Dương Nội, Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội</p>
                <p><i className="fa fa-envelope-o" /><a href="admin@vpschinhhang247.com"> admin@vpschinhhang247.com</a></p>
                <p><i className="fa fa-phone" /><a href="#"> +(84)345993883</a></p>
                <p><i className="fa fa-comments-o" /><a href="https://t.me/vpschinhhhang247"> Telegram: @vpschinhhhang247</a></p>
              </div>
            </div>
            {/* Footer About Widget End */}
            {/* Footer Widget Start */}
            <div className="col-md-3 col-sm-6 footer-widget">
              {/* Footer Title Start */}
              <h4>Company</h4>
              {/* Footer Title End */}
              <ul>
                <li>
                  <a href="/contact">Liên hệ</a>
                </li>
                <li>
                  <a href="#">Đăng ký tài khoản</a>
                </li>
                <li>
                  <a href="#">Quản lý dịch vụ</a>
                </li>
                <li>
                  <a href="/agreement">Điều khoản sử dụng</a>
                </li>
                <li>
                  <a href="/sla">Cam kết dịch vụ</a>
                </li>
              </ul>
            </div>
            {/* Footer Widget End */}
          </div>
        </div>
      </div>
      {/* Footer Area End */}
      <>
        {/* Copyright Area Start */}
        <div id="copyright">
          <div className="container">
            {/* Copyright Text Start */}
            <p className="left">
              Copyright 2023 © Since 2021 VPSCHINHHANG247. All Rights Reserved.
            </p>
            {/* Copyright Text End */}
            {/* <p className="right">
              We Accept: <img src="img/payment-methods.png" alt="" />
            </p> */}
          </div>
        </div>
        {/* Copyright Area End */}
      </>
    </>
  );
}

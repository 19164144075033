/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DedicatedServerCategory() {
  const navigate = useNavigate();
  const [isHideMenu, setIsHideMenu] = useState(false);
  return (
    <li className="dropdown">
      <a href="#" data-toggle="dropdown">
        Dedicated Server
        <span className="caret" />
      </a>
      <ul
        className="dropdown-menu"
        hidden={isHideMenu}
        style={{ width: "280px", padding: "8px", paddingLeft: '24px' }}
      >
        <div className="row">
              <li className="align-items-center">
                <div className="ms-3">
                  <h6>THEO KHU VỰC</h6>
                  <div
                    className="d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(URL.ListDedicatedServerEU);
                      setIsHideMenu(true);
                    }}
                  >
                    <a>
                      <img
                        style={{
                          alignSelf: "center",
                          marginRight: "6px",
                        }}
                        src="img/flags/european-union.png"
                        alt="server"
                        width={35}
                        height={35}
                      ></img>
                      <span>Dediacated Châu Âu</span>
                    </a>
                  </div>
                  <div
                    className="d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(URL.ListDedicatedServerUS);
                      setIsHideMenu(true);
                    }}
                  >
                    <a>
                      <img
                        style={{
                          alignSelf: "center",
                          marginRight: "6px",
                        }}
                        src="img/flags/united-states.png"
                        alt="server"
                        width={35}
                        height={35}
                      ></img>
                      <span>Dediacated Mỹ</span>
                    </a>
                  </div>
                </div>
              </li>
        </div>
      </ul>
    </li>
  );
}

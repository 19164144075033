/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <>
      {/* <div
        id="pageTitle"
        className="HeaderAdjust"
        data-bg-src="img/background-img/page-title-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="section-title">
                <h2>contact</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Contact Page</li>
              </ol>
            </div>
          </div>
        </div>
      </div> */}
      {/* Contact Area Start */}
      <div id="contact" className="page" style={{marginTop: '220px'}}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {/* Contact Address Area Start */}
              <div className="contact-address">
                <h2>Địa chỉ liên hệ</h2>
                {/* Contact Address Start */}
                <address>
                  <p>
                    <i className="fa fa-home" /> SHA5 Toà nhà Anland Lakeview, KĐT Dương Nội, Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội
                  </p>
                  <p>
                    <i className="fa fa-envelope" /> admin@vpschinhhang247.com
                  </p>
                  <p>
                    <i className="fa fa-phone" /> +(84)345993883
                  </p>
                </address>
                {/* Contact Address Area End */}
                {/* Contact Social Links Start */}
                <div className="contact-social-links">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/VPSChinhHang247">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:admin@vpschinhhang247.com">
                        <i className="fa fa-envelope" />
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/vpschinhhhang247">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Contact Social Links End */}
              </div>
              {/* Contact Address Area End */}
            </div>
            <div className="col-sm-6">
              <div className="footer-widget footer-widget-2 mt-60">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7450.608032391399!2d105.75465019532571!3d20.980447140195814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313453585629140f%3A0x284e05a1a0c4ef2c!2sANLAND%20LAKEVIEW!5e0!3m2!1sen!2s!4v1698811471829!5m2!1sen!2s"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    loading="lazy"
                  ></iframe>
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Area End */}
      {/* MAP Area Start */}
      {/* <div id="map" /> */}
      {/* MAP Area End */}
    </>
  );
}

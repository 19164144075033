import URL from "constant/url";
import { useNavigate } from "react-router-dom";
import GuideRightContent from "./GuideRightContent";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide2() {
  const navigate = useNavigate();
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3">
              Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
            </h2>
          </div>
        </div>
      </section>
      <section className="hm-blog-grids pt-40 pb-120 overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-8">
              <div className="hm-blog-grid-left bg-white deep-shadow rounded-2">
                <div className="hm2-blog-card">
                  <div className="feature-img rounded-top overflow-hidden"></div>
                  <div className="bd-content-wrapper mt-0">
                    <div className="bd-blog-meta d-flex align-items-center flex-wrap mb-4">
                      <div className="bd-blog-author d-flex align-items-center me-5">
                        <img
                          src="assets/img/author-1.png"
                          alt="author"
                          className="rounded-pill img-fluid flex-shrink-0"
                        />
                        <div className="bd-blog-author-info ms-3">
                          <span>Đăng bởi</span>
                          <h6 className="mb-0 mt-1">Admin</h6>
                        </div>
                      </div>
                      <div className="bd-blog-categories me-5 mt-3 mt-md-0">
                        <span>Trong mục</span>
                        <h6 className="mb-0 mt-1">Tutorial</h6>
                      </div>
                      <div className="bd-blog-date mt-3 mt-md-0">
                        <span>Tạo ngày</span>
                        <h6 className="mb-0 mt-1">04/03/2020</h6>
                      </div>
                    </div>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <span style={{ color: "#e74c3c" }}>
                            <strong>
                              Cách 1: Mở CMD hoặc Powershell và chạy dòng lệnh
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <strong>
                          <em>Net user administrator Mậtkhẩubạnmuốnđổi</em>
                        </strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <strong>
                          <em>
                            ví dụ: Net user administrator vpschinhhang247@12345
                          </em>
                        </strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <em>
                          Lưu ý: Mật khẩu phải bao gồm chữ IN HOA và chữ thường
                          (nếu thêm ký tự đặc biệt càng tốt)
                        </em>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <span style={{ color: "#e74c3c" }}>
                          <span style={{ fontSize: 20 }}>
                            <strong>
                              Cách 2: Thay đổi mật khẩu theo cách truyền thống
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Đầu tiên bạn vào&nbsp;<strong>Start</strong>
                        &nbsp;chọn&nbsp;
                        <strong>Control Panel</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/qWi"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="1.png"
                            src="https://image.maxserver.com/images/2016/04/11/1.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Tiếp theo bạn chọn&nbsp;
                        <strong>Change account type</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/4Bc"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="2.png"
                            src="https://image.maxserver.com/images/2016/04/11/2.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Click vào tài khoản cần đổi mật khẩu, ở đây là tài
                        khoản&nbsp;
                        <strong>Administrator</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/GJD"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="3.png"
                            src="https://image.maxserver.com/images/2016/04/11/3.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Click vào dòng&nbsp;
                        <strong>Change the password</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/TTr"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="4.png"
                            src="https://image.maxserver.com/images/2016/04/11/4.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        <span
                          style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                        >
                          Bạn nhập mật khẩu như&nbsp;hướng dẫn ở dưới, mật khẩu
                          cần có chữ in hoa, chữ thường, kí tự đặc biệt sẽ giúp
                          bảo mật tốt hơn.
                        </span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        <a
                          href="https://image.maxserver.com/image/nUM"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            alt="5.png"
                            src="https://image.maxserver.com/images/2016/04/11/5.png"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </span>
                    </p>
                    <p>
                      <span
                        style={{ fontFamily: "Arial,Helvetica,sans-serif" }}
                      >
                        - Cuối cùng&nbsp;bạn click vào&nbsp;
                        <strong>Change password</strong>
                        &nbsp;là xong.
                      </span>
                    </p>
                    <div className="bd-blog-bottom mt-4">
                      <div className="row g-4">
                        <div className="col-md-6">
                          <div className="bd-blog-share">
                            <h6>Share this Post</h6>
                            <div className="social-icons mt-3">
                              <a href="#" className="facebook">
                                <i className="fab fa-facebook-f" />
                              </a>
                              <a href="#" className="instagram">
                                <i className="fab fa-instagram" />
                              </a>
                              <a href="#" className="dribble">
                                <i className="fab fa-dribbble" />
                              </a>
                              <a href="#" className="behance">
                                <i className="fab fa-behance" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-blog-tags">
                            <h6>Related Tags.</h6>
                            <div className="tags-wrapper mt-3">
                              <a href="#" className="mb-3">
                                Domain
                              </a>
                              <a href="#" className="mb-3">
                                Web Hosting
                              </a>
                              <a href="#" className="mb-3">
                                Security
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <GuideRightContent
                children={
                  <>
                    <li>
                      <a
                        onClick={() => {
                          navigate(URL.Guide1);
                        }}
                      >
                        Hướng Dẫn Tăng Cường Bảo Mật VPS
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate(URL.Guide3);
                        }}
                      >
                        Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                      </a>
                    </li>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

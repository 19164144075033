/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import {
  actionGetListPlan,
  selectListPlanDetail,
} from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import URL from "constant/url";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true }));
  }, [dispatch]);

  return (
    <div className="row justify-content-center">
        {listPlanDetail.map((item) => (
         <div className="col-md-3 col-sm-6">
            {/* Pricing Table Item Start */}
            <div className="pricing-table-item">
              <div className="pt-head">
                <h3 style={{ fontWeight: 700 }}>
                  VPS {item.region}
                </h3>
                <div className="pt-plan">{item.price}<span> VNĐ/Tháng</span></div>
              </div>
              <div className="pt-body">
                <div className="pt-features">
                  <ul>
                    <li>CPU: {item.cpu}</li>
                    <li>RAM: {item.ram}</li>
                    <li>SSD: {item.ssd}</li>
                    <li>IPv4: {item.ipv4}</li>
                    <li>Bandwidth: {item.bandwidth}</li>
                    <li>Ethernet port: {item.ethernet_port}</li>
                  </ul>
                </div>
              </div>
              <div className="pt-footer">
                <a
                  href={URL.BuyProduct}
                  className="btn btn-custom-reverse"
                >
                  Mua ngay
                </a>
              </div>
            </div>
            {/* Pricing Table Item End */}
          </div>
        ))}
      </div>
  );
}

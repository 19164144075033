/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

import FeaturePlan from "components/FeaturePlan";

// import URL from "constant/url";
// import FeaturePlan from "components/FeaturePlan";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  return (
    <>
      {/* Header Area Start */}
      <div id="header" data-bg-video="2GqExKSwTEA" className="HeaderAdjust">
        {/* Header Slider Start */}
        <div className="header-slider">
          <div className="header-slider--item header-slider--item-1">
            <div className="container">
              <div className="row">
                <div className="col-md-5 header-item-content-holder">
                  <div className="vc-parent">
                    <div className="vc-child">
                      {/* Header Slider Content Start */}
                      <div className="header-item-content">
                        <h2>Dịch vụ đa dạng</h2>
                        <p>
                          Chúng tôi tự tin với kinh nghiệm 10 năm kinh doanh, sẵn sàng đem đến cho khách hàng những dịch vụ chất lượng nhất.
                        </p>
                        <div className="list clearfix">
                          <p>
                            <i className="fa fa-check" />
                            Máy chủ VPS
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Máy chủ Proxy
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Máy chủ Dedicated
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Máy chủ theo yêu cầu
                          </p>
                        </div>
                        <div className="price">
                          <a href="https://manage.vpschinhhang247.com/management" className="btn btn-lg btn-custom-reverse">
                            Mua ngay
                          </a>
                        </div>
                      </div>
                      {/* Header Slider Content End */}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 header-item-img">
                  <div className="vc-parent">
                    <div className="vc-child">
                      <figure className="clearfix">
                        <img
                          src="img/header-slider-img/01.png"
                          alt=""
                          className="img-responsive owl-fadeInUp"
                        />
                        <figcaption>
                          <div className="header-item-badge header-item-badge-1 owl-fadeInLeft">
                            <p>
                              Chỉ từ<span>60,000</span>VNĐ/tháng
                            </p>
                          </div>
                          <div className="header-item-badge header-item-badge-2 owl-fadeInRight">
                            <p>
                              Giảm tới<span>10%</span>
                            </p>
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-slider--item header-slider--item-2">
            <div className="container">
              <div className="row">
                <div className="col-md-5 header-item-content-holder">
                  <div className="vc-parent">
                    <div className="vc-child">
                      {/* Header Slider Content Start */}
                      <div className="header-item-content">
                        <h2>Máy chủ Proxy</h2>
                        <p>
                          IP đa dạng, dễ dàng cài đặt, một người sử dụng duy nhất, cam kết không chia sẻ.
                        </p>
                        <div className="list clearfix">
                          <p>
                            <i className="fa fa-check" />
                            Cho phép đổi IP,  cài đặt lại.
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Một người sử dụng duy nhất
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Hỗ trợ HTTPS, SOCK5
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Băng thông không giới hạn.
                          </p>
                        </div>
                        <div className="price">
                          <a href="#" className="btn btn-lg btn-custom-reverse">
                            Mua Ngay
                          </a>
                        </div>
                      </div>
                      {/* Header Slider Content End */}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 header-item-img">
                  <div className="vc-parent">
                    <div className="vc-child">
                      <figure className="clearfix">
                        <img
                          src="img/header-slider-img/02.png"
                          alt=""
                          className="img-responsive owl-fadeInUp"
                        />
                        <figcaption>
                          <div className="header-item-badge header-item-badge-1 owl-fadeInLeft">
                            <p>
                              Chỉ từ<span>60.000</span>VNĐ/tháng
                            </p>
                          </div>
                          <div className="header-item-badge header-item-badge-2 owl-fadeInRight">
                            <p>
                              Giảm tới<span>10%</span>
                            </p>
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-slider--item header-slider--item-3">
            <div className="container">
              <div className="row">
                <div className="col-md-5 header-item-content-holder">
                  <div className="vc-parent">
                    <div className="vc-child">
                      {/* Header Slider Content Start */}
                      <div className="header-item-content">
                        <h2>Máy chủ VPS</h2>
                        <p>
                          Đa dạng quốc gia, nhiều dải IP mang đến nhiều sự lựa chọn.
                        </p>
                        <div className="list clearfix">
                          <p>
                            <i className="fa fa-check" />
                            Đa dạng quốc gia
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Giá cả phù hợp
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Dễ Dàng Cài Đặt
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Tiêu Chuẩn Quốc Tế
                          </p>
                        </div>
                        <div className="price">
                          <a href="#" className="btn btn-lg btn-custom-reverse">
                            Mua ngay
                          </a>
                        </div>
                      </div>
                      {/* Header Slider Content End */}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 header-item-img">
                  <div className="vc-parent">
                    <div className="vc-child">
                      <figure className="clearfix">
                        <img
                          src="img/header-slider-img/03.png"
                          alt=""
                          className="img-responsive owl-fadeInUp"
                        />
                        <figcaption>
                          <div className="header-item-badge header-item-badge-1 owl-fadeInLeft">
                            <p>
                              Chỉ từ<span>120.000</span>VNĐ/tháng
                            </p>
                          </div>
                          <div className="header-item-badge header-item-badge-2 owl-fadeInRight">
                            <p>
                              Giảm tới<span>10%</span>
                            </p>
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-slider--item header-slider--item-4">
            <div className="container">
              <div className="row">
                <div className="col-md-5 header-item-content-holder">
                  <div className="vc-parent">
                    <div className="vc-child">
                      {/* Header Slider Content Start */}
                      <div className="header-item-content">
                        <h2>Dedicated Server</h2>
                        <p>
                          Liên hệ để có giá tốt nhất.
                        </p>
                        <div className="list clearfix">
                          <p>
                            <i className="fa fa-check" />
                            Cấu hình cao
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Đường truyền mạnh mẽ
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Giá cả phù hợp
                          </p>
                          <p>
                            <i className="fa fa-check" />
                            Ổ cứng SSD
                          </p>
                        </div>
                        <div className="price">
                          <a href="#" className="btn btn-lg btn-custom-reverse">
                            View Details
                          </a>
                        </div>
                      </div>
                      {/* Header Slider Content End */}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 header-item-img">
                  <div className="vc-parent">
                    <div className="vc-child">
                      <figure className="clearfix">
                        <img
                          src="img/header-slider-img/04.png"
                          alt=""
                          className="img-responsive owl-fadeInUp"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header Slider End */}
        {/* Header Slider Nav Start */}
        <div className="header--slider-nav">
          <div className="container">
            <ul>
            <li>
                <p>
                  <i className="fa fa-hdd-o" />
                  <span>Dịch vụ đa dạng</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-hdd-o" />
                  <span>Proxy</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-cloud" />
                  <span>VPS</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-server" />
                  <span>Dedicated</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/* Header Slider Nav End */}
      </div>
      {/* Header Area End */}
      {/* Features Area Start */}
      <div id="features">
        <div className="container">
          {/* Section Title Start */}
          <div className="section-title">
            <h2>Vì sao chọn chúng tôi</h2>
          </div>
          {/* Section Title End */}
          <div className="row vc--features">
            <div className="col-md-4 features--img">
              <img
                src="img/features-img/cloudHosting.png"
                alt=""
                className="img-responsive"
              />
            </div>
            <div className="col-md-8">
              <div className="row">
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/ssd-disk.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>SSD Chuyên Dụng</h4>
                    <p>
                    Sử dụng ổ SSD mang lại tốc độ tốt nhất cho khách hàng.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/02.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>Bảo mật dữ liệu</h4>
                    <p>
                      Dự liệu khách hàng được mã hóa và bảo mật.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/03.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>Tiêu Chuẩn Quốc Tế</h4>
                    <p>
                      Máy chủ vận hành tại các Trung tâm dữ liệu Tier 3 chuẩn quốc tế.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/04.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>Chính Sách Hoàn Trả</h4>
                    <p>
                      Hoàn tiền không cần lý do trong trường hợp khách hàng không hài lòng.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/05.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>Hỗ Trợ 24/7</h4>
                    <p>
                      Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng cho mọi tình huống, hỗ trợ nhanh chóng.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
                {/* Feature Item Start */}
                <div className="col-md-4 col-sm-6 feature-item">
                  <div className="feature-item-icon">
                    <img
                      src="img/features-img/06.png"
                      alt=""
                      className="img-responsive"
                    />
                  </div>
                  <div className="feature-item-content">
                    <h4>Cài đặt dễ dàng</h4>
                    <p>
                      Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng trong phần Quản Trị.
                    </p>
                  </div>
                </div>
                {/* Feature Item End */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Area End */}
      {/* Single Features Area Start */}
      <div id="singleFeatures">
        <div
          className="single-feature single-feature-1"
          data-bg-src="img/background-img/single-feature-1-bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5 single-feature--image">
                <img
                  src="img/features-img/drag-and-drop.png"
                  alt=""
                  className="img-responsive"
                />
              </div>
              <div className="col-md-7 single-feature--content">
                <h2>Đa dạng tùy chọn, đa dạng Hệ điều hành</h2>
                <p>
                  Quý khách có thể tùy chọn cài đặt máy chủ tùy theo nhu cầu, cài đặt các phần mềm cơ bản,
                  cài sẵn hệ điều hành cần thiết. Toàn bộ quá trình được vận hành tự động đảm bảo an toàn,
                  bảo mật thông tin khách hàng.
                </p>
                <a href="https://manage.vpschinhhang247.com/management" className="btn btn-custom-reverse">
                  Chi tiết
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="single-feature single-feature-2">
          <div className="container">
            <div className="row">
              <div className="col-md-7 single-feature--content">
                <h2>Cài đặt nhanh chóng và dễ dàng</h2>
                <p>
                  Hệ thống quản lý được thiết kế riêng biệt và chuyên nghiệp, giúp khách hàng có thể 
                  nhanh chóng và thuận tiện quản lý, tạo mới các dịch vụ.
                </p>
                <a href="https://manage.vpschinhhang247.com/management" className="btn btn-custom-reverse">
                  Tới trang quản lý
                </a>
              </div>
              <div className="col-md-5 single-feature--image">
                <img
                  src="img/features-img/softaculous.png"
                  alt=""
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Single Features Area End */}
      {/* Pricing Area Start */}
      <div id="pricing">
        <div className="container">
          {/* Section Title Start */}
          <div className="section-title">
            <h2>Những Plan Tiêu Biểu</h2>
          </div>
          {/* Section Title End */}
          <FeaturePlan/>
        </div>
      </div>
      {/* Pricing Area End */}
      {/* Certificate Area Start */}
      {/* <div id="certificate">
        <div className="container">
          <h2 className="h1">
            100% Powered by Certified Green Renewable Energy Sources
          </h2>
          <div className="certificate--img">
            <img src="https://www.hostpapa.eu/assets/green/bef.jpg" alt="" />
            <img
              src="https://www.hostpapa.eu/assets/green/greentags.jpg"
              alt=""
            />
          </div>
        </div>
      </div> */}
      {/* Certificate Area Start */}
    </>
  );
}
